<template>
  <div style="position: relative">
    <div style="position: absolute; top: 6vh; left: 20vw; width: 50%">
      <el-form ref="form" :data="form" label-width="120px">
        <el-form-item label="secret:">
          <el-input v-model="form.secret"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">保存</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {
        secret: "",
      },
    };
  },
  mounted() {
    this.getDetails()
  },
  methods: {
    // 获取回显信息
    getDetails(){
      this.$get("/jxct/detail").then((res) => {
        if (res.data.state == "success") {
          this.form.secret = res.data.data.secret
        }
      });
    },
    onSubmit() {
      this.$confirm("是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$postJSON('/jxct/save', {
          secret: this.form.secret
        }
        ).then((res) => {
          if (res.data.state == 'success') {
            this.$message.success('保存成功')
          } else {
            this.$message.error(res.data.msg)
          }
        })
      });
    },
  },
};
</script>
